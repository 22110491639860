import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  Badge,
  Popover,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Button,
  ListItemIcon,
  Stack,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Collapse,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import EmailIcon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { format } from 'date-fns';

import { getReduxNotifications, changeReadStatus } from '../../../redux/slices/notifications';
import Iconify from '../../../components/iconify';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const NotificationsPopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [openTickets, setOpenTickets] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { list } = useSelector((state) => state.notifications);
  const totalUnread = list.filter((item) => !item.isRead);

  useEffect(() => {
    dispatch(getReduxNotifications());
  }, [dispatch]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    handleClose();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const handleTicketClick = (ticketNumber) => {
    setOpenTickets((prevOpenTickets) =>
      prevOpenTickets.includes(ticketNumber)
        ? prevOpenTickets.filter((ticket) => ticket !== ticketNumber)
        : [...prevOpenTickets, ticketNumber]
    );
  };

  const handleNotificationClick = useCallback(
    (notification) => {
      dispatch(changeReadStatus(notification.id));
      const department = notification.department || notification.departmentName;
      navigate(`/dashboard/tickets/${department}/${notification.ticketNumber}`);
      handleClose();
      handleCloseDialog();
    },
    [dispatch, navigate]
  );

  const open = Boolean(anchorEl);

  const renderIcon = (type) => {
    switch (type) {
      case 'order_placed':
        return <ShoppingBasketIcon sx={{ color: 'orange' }} />;
      case 'order_shipped':
        return <LocalShippingIcon sx={{ color: 'orange' }} />;
      case 'mail':
        return <EmailIcon sx={{ color: 'orange' }} />;
      case 'chat_message':
        return <ChatIcon sx={{ color: 'orange' }} />;
      default:
        return <Iconify icon="mingcute:notification-fill" sx={{ color: 'orange' }} />;
    }
  };

  const renderNotificationItem = (notification) => (
    <ListItem
      key={notification.id}
      button
      onClick={() => handleNotificationClick(notification)}
      sx={{
        backgroundColor: notification.isRead ? 'inherit' : 'action.hover',
      }}
    >
      <ListItemIcon>{renderIcon(notification.type)}</ListItemIcon>
      <ListItemText
        primary={<Typography variant="subtitle2">{notification.title}</Typography>}
        secondary={
          <Stack>
            <Typography variant="body2" color="text.secondary">
              {notification.description}
            </Typography>
            <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
              <Iconify
                icon={!notification.isRead && 'eva:clock-fill'}
                width={16}
                sx={{ mr: 0.5, color: 'orange' }}
              />
              <Typography variant="caption">
                {format(new Date(...notification.scheduledTime), 'dd-MM-yyyy HH:mm')}
              </Typography>
            </Stack>
          </Stack>
        }
      />
    </ListItem>
  );

  const groupedNotifications = list.reduce((acc, notification) => {
    const department = notification.department || notification.departmentName;
    const { ticketNumber } = notification;

    if (!acc[department]) {
      acc[department] = {};
    }
    if (!acc[department][ticketNumber]) {
      acc[department][ticketNumber] = [];
    }
    acc[department][ticketNumber].push(notification);
    return acc;
  }, {});

  const departments = Object.keys(groupedNotifications);

  const filteredNotifications = selectedDepartment
    ? groupedNotifications[selectedDepartment]
    : Object.assign({}, ...Object.values(groupedNotifications));

  return (
    <>
      <IconButton onClick={handleClick} size="large" color="inherit">
        <Badge badgeContent={totalUnread.length} color="error">
          <NotificationsIcon sx={{ color: 'orange' }} />
        </Badge>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: { width: '400px', maxHeight: '70vh' },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Notificări
          </Typography>
          <List>{list.slice(0, 5).map(renderNotificationItem)}</List>
          <Button fullWidth variant="contained" onClick={handleOpenDialog} sx={{ mt: 2 }}>
            Vezi toate notificările
          </Button>
        </Box>
      </Popover>

      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: 'white' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="închide"
            >
              <CloseIcon sx={{ color: 'black' }} />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color: 'black' }} variant="h6" component="div">
              Toate Notificările
            </Typography>
            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <InputLabel id="department-select-label">Departament</InputLabel>
              <Select
                labelId="department-select-label"
                id="department-select"
                value={selectedDepartment}
                onChange={handleDepartmentChange}
                label="Departament"
              >
                <MenuItem value="">Toate</MenuItem>
                {departments.map((department) => (
                  <MenuItem key={department} value={department}>
                    {department}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Toolbar>
        </AppBar>
        <List>
          {Object.entries(filteredNotifications).map(([ticketNumber, notifications]) => (
            <React.Fragment key={ticketNumber}>
              <ListItem button onClick={() => handleTicketClick(ticketNumber)}>
                <ListItemText primary={`Solicitare ${ticketNumber}`} />
                {openTickets.includes(ticketNumber) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={openTickets.includes(ticketNumber)} timeout="auto" unmountOnExit>
                <List disablePadding>{notifications.map(renderNotificationItem)}</List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </Dialog>
    </>
  );
};

export default NotificationsPopover;
